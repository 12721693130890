<template>
	<v-row justify="center">
		<MySnackbar
			v-model="alert.show"
			:type="alert.type"
			:text="alert.text"
		/>
		<v-dialog ref="dialog" v-model="modal" width="600px">
			<!--			<template v-slot:activator="{ on, attrs }" v-if="withButton">-->
			<!--				<div class="mr-auto">-->
			<!--					<v-btn-->
			<!--						color="error"-->
			<!--						class="white&#45;&#45;text d-flex "-->
			<!--						v-bind="attrs"-->
			<!--						v-on="on"-->
			<!--						rounded-->
			<!--					>-->
			<!--						<v-icon left dark>-->
			<!--							mdi-lock-->
			<!--						</v-icon>-->
			<!--						تغییر رمز عبور-->
			<!--					</v-btn>-->
			<!--				</div>-->
			<!--			</template>-->
			<v-card style="background-color:white" class="text-center">
				<v-card-text>
					<v-avatar size="64" class="my-6 ico-shadow">
						<v-icon size="32" style="border-radius: 50px;">
							mdi-lock
						</v-icon>
					</v-avatar>
					<h2>ویرایش رمز عبور</h2>
					<v-form ref="form" class="pa-2">
						<v-text-field
							v-model="password.value"
							:append-icon="
								password.show ? 'mdi-eye' : 'mdi-eye-off'
							"
							:rules="[rules.required]"
							:type="password.show ? 'text' : 'password'"
							outlined
							label="رمز عبور فعلی"
							counter
							@click:append="password.show = !password.show"
						/>
						<v-text-field
							v-model="newpassword.value"
							:append-icon="
								newpassword.show ? 'mdi-eye' : 'mdi-eye-off'
							"
							:rules="[rules.required, rules.min]"
							:type="newpassword.show ? 'text' : 'password'"
							outlined
							label="رمز عبور جدید"
							counter
							@click:append="newpassword.show = !newpassword.show"
						/>
						<v-text-field
							v-model="newPasswordRepeat.value"
							:append-icon="
								newPasswordRepeat.show
									? 'mdi-eye'
									: 'mdi-eye-off'
							"
							:rules="[
								rules.required,
								rules.min,
								passwordConfirmationRule,
							]"
							:type="newPasswordRepeat.show ? 'text' : 'password'"
							outlined
							label="تکرار رمز عبور جدید"
							counter
							@click:append="
								newPasswordRepeat.show = !newPasswordRepeat.show
							"
						/>
					</v-form>
					<v-row align="center" justify="end" class="mt-2">
						<v-btn
							color="success"
							@click="onOkClick"
							:loading="loading"
							class="ml-4 mb-2"
						>
							تغییر رمز عبور
						</v-btn>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { Constants, getErrorText } from "../constants/GlobalConstants";
import VFormBase from "vuetify-form-base";
import MyAxios from "../constants/MyAxios";
import MySnackbar from "../components/MySnackbar";
export default {
	components: { VFormBase, MySnackbar },
	data() {
		return {
			modal: false,
			loading: false,
			alert: {
				show: false,
				text: "",
				type: "error",
			},

			password: {
				value: "",
				show: false,
			},
			newpassword: {
				value: "",
				show: false,
			},
			newPasswordRepeat: {
				value: "",
				show: false,
			},

			rules: Constants.rules,
		};
	},
	props: {
		// value: {
		// 	// v-model instance
		// 	type: String,
		// 	required: true,
		// },
		withModel: {
			type: Boolean,
			default: true,
		},
		withButton: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		onOkClick() {
			if (this.$refs.form.validate()) {
				if (this.withModel) this.$emit("input", this.newpassword.value);
				else this.PostNewPassword(this.newpassword.value);
			}
		},
		onChangeBtnClick() {
			this.modal = true;
		},
		onClose() {
			this.modal = false;

			this.password = {
				value: "",
				show: false,
			};
			this.newpassword = {
				value: "",
				show: false,
			};
			this.newPasswordRepeat = {
				value: "",
				show: false,
			};
		},
		PostNewPassword() {
			if (!this.loading) this.loading = true;

			MyAxios.post("password/update", {
				password: this.password.value,
				newPassword: this.newpassword.value,
				newPassword_confirmation: this.newPasswordRepeat.value,
			})
				.then((response) => {
					this.loading = false;
					this.alert = {
						show: true,
						text: "رمز شما با موفقیت تغییر کرد",
						type: "success",
					};
					this.modal = false;
					console.log(response);
				})
				.catch((error) => {
					this.loading = false;
					this.alert = {
						show: true,
						text: getErrorText(error),
						type: "error",
					};
					console.log(error, error.response);
				});
		},
	},
	computed: {
		passwordConfirmationRule() {
			return (
				this.newpassword.value === this.newPasswordRepeat.value ||
				"تکرار رمز عبور با رمز عبور جدید یکسان نیست."
			);
		},
	},
};
</script>

<style scoped>
.ico-shadow {
	box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
</style>
